import webBiLogger from '@wix/web-bi-logger';
import Experiments from '@wix/wix-experiments';
import {EStep} from '../bi/params';
import {OnBoardingApi} from '../services/onboarding-api';
import {OnBoardingStore} from '../stores/onboarding-store';
import {IEditorSDK, SetCurrentStep} from '../types/wix-types';
import {enrichLanguage, resolveAppUrl} from '../utils/editorSDKhelper';
import {createLinguistAPI} from './editorScriptHelper';
import {EOrigin} from '../bi/params';
import {EditorType, OnboardingType} from '../types/TWixTypes';

// shame to use ./bi. only for 2 BIs
const factory = webBiLogger.factory({endpoint: 'linguist'});
const logger = factory.logger();
let experiments: Experiments;
let _editorSDK: IEditorSDK;
let _token: string;
let currentStep: string;
let translation: {
  MULTILINGUAL_ONBOARDING_MODAL_TITLE: string;
  Multilingual_Onboarding_Select_Main_Language_Modal_Header: string;
};
let appDefId: string;
let linguistAPI: OnBoardingApi;
let onboardingOrigin: EOrigin;
let editorType: EditorType;
// https://github.com/wix-private/santa-editor/commit/f5b022cd277a86a30aaad3a107943ac970a1fe84#diff-ac44ade209822fbc5afcd6c19d792542R213
export enum OnboardingEventType {
  openOnboarding = 'openOnboarding',
  openDashboard = 'openDashboard',
  openPackagePicker = 'openPackagePicker',
}

interface OnboardingEvent {
  eventType: OnboardingEventType;
  eventPayload?: any;
}

export const FRAME_SIZE = {
  [EditorType.CLASSIC]: {
    [OnboardingType.DEFAULT]: {
      width: 600,
      height: 576,
    },
    [OnboardingType.SHORT]: {
      width: 600,
      height: 254,
    },
  },
  [EditorType.RESPONSIVE]: {
    [OnboardingType.DEFAULT]: {
      width: 600,
      height: 520,
    },
    [OnboardingType.SHORT]: {
      width: 600,
      height: 198,
    },
  },
}

const setCurrentStep: SetCurrentStep = (step) => (currentStep = step);
const onEvent = (event: OnboardingEvent) => {
  if (!event) {
    return;
  }
  switch (event.eventType) {
    case OnboardingEventType.openOnboarding:
      if (event.eventPayload?.origin) {
        onboardingOrigin = event.eventPayload?.origin;
      }
      openOnboardingModal();
      break;
    case OnboardingEventType.openDashboard:
      openDashboardModal();
    case OnboardingEventType.openPackagePicker:
      openPackagePicker(
        {
          words_in_credit: event.eventPayload?.wordsInCredit,
          mainLanguage: event.eventPayload?.mainLanguage,
          secondaryLanguage: event.eventPayload?.secondaryLanguage,
          origin: event.eventPayload?.origin,
          words_translated: event.eventPayload?.translatedWords,
          words_remain: event.eventPayload?.wordsRemain,
          words_needed: event.eventPayload?.wordsNeeded,
          is_credit_enough: event.eventPayload?.hasEnoughWords,
        },
        event.eventPayload?.modalTitle
      );
      break;
    default:
  }
};

const openDashboardModal = async () => {
  await _editorSDK.editor.openDashboardPanel(_token, {url: 'multilingual', closeOtherPanels: true});
  const instance = OnBoardingStore.extractInstanceId(await _editorSDK.info.getAppInstance());
  const {
    data: {
      data: {originalLanguage, translationLanguages},
    },
  } = await linguistAPI.getLanguagesStatus(instance);
  _editorSDK.language.setLanguages(_token, {
    originalLanguage: enrichLanguage(originalLanguage[0]),
    translationLanguages: translationLanguages.map(enrichLanguage),
  });
};

const openPackagePicker = async (params?: any, modalTitle?: string) => {
  const queryParams = Object.keys(params)
    .map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
    })
    .join('&');
  const metaSiteId = await _editorSDK.document.info.getMetaSiteId();
  await _editorSDK.editor.openModalPanel(_token, {
    title: modalTitle ?? 'Auto Translate Word Credits',
    url: `https://manage.wix.com/dashboard/${metaSiteId}/multilingual/package-picker${
      queryParams ? '?' + queryParams : ''
    }`,
    width: '94%',
    height: '94%',
    centered: true,
    noPanelPadding: true,
  });
};

const handleAction = async (action: {type: string; payload: object}) => {
  if (action.type === 'beforeRemoveApp') {
    const origLang = await _editorSDK.language.original.get();
    const translationLanguages = await _editorSDK.language.getTranslationLanguages();
    const allComponents = await _editorSDK.document.components.getAllComponents(_token);
    let index = 0;
    while (index < allComponents?.length) {
      const componentRef = allComponents[index];
      const componentRefType = await _editorSDK.document.components.getType(_token, {componentRef});
      if (!componentRef.id.includes('_r_')) {
        /*checking it's not a referred component. https://jira.wixpress.com/browse/DM-6188*/
        if (componentRefType === 'wysiwyg.viewer.components.LanguageSelector') {
          _editorSDK.document.components.remove(_token, {componentRef});
        } else {
          translationLanguages.forEach((lang) => {
            _editorSDK.language.component.data.remove(_token, {
              languageCode: lang.code,
              componentRef,
            });
          });
        }
      }
      index++;
    }
    _editorSDK.language.setLanguages(_token, {
      originalLanguage: {...origLang, languageCode: origLang.code},
      translationLanguages: [],
    });
  }
};

/**
 * Function fo calculating frame size.
 * Frame size now depends on:
 * 1. Onboarding type: short onboarding needs much less space
 * 2. Editor type:
 *   - for responsive editor size of frame represents size of content inside modal
 *   - for classic editor size represents size of modal itself
 */
const getIframeSizes = ({isResponsiveEditor, isShortOnboardingEnabled}: {isResponsiveEditor: boolean; isShortOnboardingEnabled: boolean}) => {
  const editorKey = isResponsiveEditor ? EditorType.RESPONSIVE : EditorType.CLASSIC;
  const onboardingKey = isShortOnboardingEnabled ? OnboardingType.SHORT : OnboardingType.DEFAULT;

  return FRAME_SIZE[editorKey][onboardingKey];
};

const editorReady = async (
  editorSDK: IEditorSDK,
  token: string,
  {firstInstall, origin}: {firstInstall: boolean; origin: {type: EditorType; info?: {type: EOrigin}}}
) => {
  const locale = await editorSDK.environment.getLocale();
  onboardingOrigin = origin.info?.type ?? EOrigin.ADD_PANEL;
  editorType = origin.type;
  try {
    translation = require(`../assets/locale/gen_EditorScript/messages_${locale}.json`);
  } catch (e) {
    translation = require(`../assets/locale/gen_EditorScript/messages_en.json`);
  }
  _editorSDK = editorSDK;
  _token = token;
  appDefId = await editorSDK.info.getAppDefinitionId(token);
  const editorSessionId = await editorSDK.editor.info.getEditorSessionId();
  const metaSiteId = await editorSDK.document.info.getMetaSiteId();
  factory.updateDefaults({src: 89, msid: metaSiteId, esi: editorSessionId});

  _editorSDK.editor.setAppAPI(token, {
    setCurrentStep,
  });

  experiments = new Experiments({scope: 'multilingual-app', useNewApi: true});
  if (firstInstall) {
    linguistAPI = await createLinguistAPI(editorSDK);
    await openModal();
  }
};

async function openOnboardingModal() {
  await experiments.ready();
  const isGoogleLimitationsEnabled = experiments.enabled('specs.UseGoogleTranslateLimitation');
  const isShortOnboardingEnabled = isGoogleLimitationsEnabled && experiments.enabled('specs.ml.onboardingV2Short');
  const {width, height} = getIframeSizes({isShortOnboardingEnabled, isResponsiveEditor: editorType === EditorType.RESPONSIVE});
  const options = {
    url: resolveAppUrl(appDefId),
    initialData: {origin: onboardingOrigin.toLowerCase(), editorType},
    title: isShortOnboardingEnabled ? translation.Multilingual_Onboarding_Select_Main_Language_Modal_Header : translation.MULTILINGUAL_ONBOARDING_MODAL_TITLE,
    width,
    height,
  };

  const result = await _editorSDK.editor.openModalPanel(_token, {
    ...options,
    componentRef: {},
  });

  if (!result) {
    if (currentStep === EStep.WHATS_NEXT) {
      logger.log({evid: 222, finish_method: 'X'});
      return;
    } else {
      logger.log({evid: 306, finish_method: 'X'});
    }

    if (experiments.enabled('specs.ml.disableGetLanguageAndLocaleFromEnv')) {
      const originalLanguage = {
        code: 'en',
        locale: 'en-US',
      };
      const translationLanguages = [
        {
          code: 'es',
          locale: 'es-ES',
        },
      ];
      _editorSDK.language.setLanguages(_token, {
        originalLanguage: enrichLanguage(originalLanguage),
        translationLanguages: translationLanguages.map(enrichLanguage),
      });
      _editorSDK.application.uninstall(_token, {openConfirmation: false});
    }
  }
}

async function openModal() {
  await experiments.ready();
  const googleLimitationEnabled = experiments.enabled('specs.UseGoogleTranslateLimitation');
  const [mainLanguage, secondaryLanguages, isMultilingualEnabled] = await Promise.all([
    _editorSDK.language.original.get(),
    _editorSDK.language.getTranslationLanguages(),
    _editorSDK.language.multilingual.isEnabled(),
    experiments.ready(),
  ]);
  logger.log({
    evid: 280,
    main_language: mainLanguage?.code || '',
    secondary_languages: secondaryLanguages.map(({code}) => code).join(','),
  });
  if (
    (!googleLimitationEnabled && secondaryLanguages.length) ||
    (googleLimitationEnabled && isMultilingualEnabled)
  ) {
    openDashboardModal();
  } else {
    openOnboardingModal();
  }
}

export {onEvent, setCurrentStep, editorReady, handleAction, getIframeSizes};
