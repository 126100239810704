import {TExperiments} from './TExperiments';
import {InjectedExperimentsProps} from '@wix/wix-experiments-react';
import {Omit} from 'lodash';
import Experiments from '@wix/wix-experiments';

export interface IWixWithExperiment extends Omit<InjectedExperimentsProps, 'experiments'> {
  experiments: TypedExperiments;
}

export type TWixExperimentsList = {
  [key in TExperiments]: string;
};

export declare class TypedExperiments extends Experiments {
  enabled(key: TExperiments): boolean;
  get<T extends string | undefined = undefined>(key: TExperiments): T extends undefined ? 'new' | 'old' : T;
}

/**
 * Source: https://github.com/wix-private/editor-platform/blob/master/packages/editor-platform-sdk-types/src/definitions/origin.ts
 */
export enum EditorType {
  CLASSIC = 'CLASSIC',
  RESPONSIVE = 'RESPONSIVE',
}

export enum OnboardingType {
  DEFAULT = 'default',
  // under specs.ml.onboardingV2Short
  SHORT = 'short',
}
